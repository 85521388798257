import axios from "axios"
import { getInstance } from "../auth/index";

export default {
  async getUserDetails(email){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let res = await axios.get("https://tpagembira.azurewebsites.net/profile/" + email, { headers: {authorization: `Bearer ${accessToken}`}});
    // let res = await axios.get("http://localhost:3000/profile/" + email, { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  },
  async upsertUserDetails(userdata){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let options = {
      method: 'POST',
      url: "https://tpagembira.azurewebsites.net/profile",
      // url: "http://localhost:3000/profile",
      headers: {
        'content-type': 'application/json',
        'authorization': 'Bearer ' + accessToken
      },
      data: userdata
    }

    axios.request(options)
      .catch(function (error) {
        console.error(error);
      })
    return "OK";
  },
}