<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <b-container v-if="!loading">
      <h5 class="text-center text-primary">My Profile*</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col>
            <div class="mt-4" />
            <strong class="text-primary">The following identity is used in this website</strong>
            <b-form @submit.prevent="onSubmit" @reset.prevent>
              <b-form-group id="input-group-4" label="E-mail" label-for="input-4">
                <b-form-input
                  id="input-4"
                  type="email"
                  v-model="form.email"
                  placeholder="Enter your e-mail"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label="Given name"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  type="text"
                  v-model="form.given_name"
                  placeholder="Enter your given name/first name"
                  :disabled="!form.user_id.startsWith('auth0|')"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Family name" label-for="input-2">
                <b-form-input
                  id="input-2"
                  type="text"
                  v-model="form.family_name"
                  placeholder="Enter your family name/last name"
                  :disabled="!form.user_id.startsWith('auth0|')"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-3" label="Nickname" label-for="input-3">
                <b-form-input
                  id="input-3"
                  type="text"
                  v-model="form.nickname"
                  placeholder="Enter nickname to be used in the website"
                  :disabled="!form.user_id.startsWith('auth0|')"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-5" label="Mobile" label-for="input-5">
                <b-form-input
                  id="input-5"
                  type="tel"
                  v-model="form.user_metadata.mobile"
                  placeholder="Enter mobile number, e.g., 0654321234"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-6" label="Street name and number" label-for="input-6">
                <b-form-input
                  id="input-6"
                  type="text"
                  v-model="form.user_metadata.address.street"
                  placeholder="Enter street address, e.g., Venbergsemolen 1"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-7" label="Postcode" label-for="input-7">
                <b-form-input
                  id="input-7"
                  type="text"
                  v-model="form.user_metadata.address.postcode"
                  placeholder="Enter postcode, e.g., 5612AB"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-8" label="City/Town" label-for="input-8">
                <b-form-input
                  id="input-8"
                  type="text"
                  v-model="form.user_metadata.address.city"
                  placeholder="Enter city, e.g., Eindhoven"
                ></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-9" label="Country" label-for="input-9">
                <b-form-input
                  id="input-9"
                  type="text"
                  v-model="form.user_metadata.address.country"
                  placeholder="Enter country of residence, e.g., The Netherlands"
                ></b-form-input>
              </b-form-group>

              <b-button type="submit" variant="primary">Update own profile</b-button>

              <div class="mt-4" />
              <strong><span class="text-primary">*</span> Non editable fields can only be changed directly on external identity provider sites used to sign up the accounts (e.g., Google Account). </strong>

              <b-alert 
                :show="dismissCountDown" 
                dismissible 
                variant="success" 
                @dismissed="dismissCountDown=0"
                @dismiss-count-down="countDownChanged"
                >
                {{ alertMessage }}
              </b-alert>
              <b-alert
                :show="dismissErrCountDown"
                dismissible
                variant="danger"
                @dismissed="dismissErrCountDown=0"
                @dismiss-count-down="countDownErrChanged"
              >
                {{ alertErrMessage }}
              </b-alert>

            </b-form>
          </b-col>
        </b-row>
        <b-row v-if="typeof(form.user_metadata.parentsof)!=='undefined' && form.user_metadata.parentsof.length > 0">
          <b-col>
            <div class="mt-4" />
            <b-form @submit.prevent="onSubmitChildren" @reset.prevent>
              <div v-for="(child, index) in form.user_metadata.parentsof" :key="child.email">
                <strong class="text-primary">Child #{{index + 1}}**</strong>
                <b-form-group id="input-group-4" label="E-mail" label-for="input-4">
                  <b-form-input
                    id="input-4"
                    type="email"
                    v-model="child.email"
                    placeholder="Enter your e-mail"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Given name"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="child.given_name"
                    placeholder="Enter your given name/first name"
                    :disabled="!child.user_id.startsWith('auth0|')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label="Family name" label-for="input-2">
                  <b-form-input
                    id="input-2"
                    type="text"
                    v-model="child.family_name"
                    placeholder="Enter your family name/last name"
                    :disabled="!child.user_id.startsWith('auth0|')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-3" label="Nickname" label-for="input-3">
                  <b-form-input
                    id="input-3"
                    type="text"
                    v-model="child.nickname"
                    placeholder="Enter nickname to be used in the website"
                    :disabled="!child.user_id.startsWith('auth0|')"
                  ></b-form-input>
                </b-form-group>
              </div>
              <b-button type="submit" variant="primary">Update children details</b-button>
              <div class="mt-4" />
              <div><strong><span class="text-primary">*</span> Non editable fields can only be changed directly on external identity provider sites used to sign up the accounts (e.g., Google Account). </strong></div>
              <div><strong><span class="text-primary">**</span> Children's addresses and mobile phones follow their parents'. If this is not the case, please request admin to make the adjustment.</strong></div>

              <div class="mt-4" />
              <b-alert 
                :show="dismissChildCountDown" 
                dismissible 
                variant="success" 
                @dismissed="dismissChildCountDown=0"
                @dismiss-count-down="countDownChildChanged"
                >
                {{ alertChildMessage }}
              </b-alert>
              <b-alert
                :show="dismissErrChildCountDown"
                dismissible
                variant="danger"
                @dismissed="dismissErrChildCountDown=0"
                @dismiss-count-down="countDownErrChildChanged"
              >
                {{ alertErrChildMessage }}
              </b-alert>
              <div class="mt-4" />
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <div class="mt-3" />
  </div>
</template>

<script>
  import ProfileService from '@/services/ProfileService.js';
  export default {
    name: 'profile',
    title: "TPA Gembira - Profile",
    data() {
      return {
        loading: false,
        alertMessage: "",
        alertErrMessage: "",
        dismissSecs: 5,
        dismissCountDown: 0,
        dismissErrSecs: 5,
        dismissErrCountDown: 0,

        alertChildMessage: "",
        alertErrChildMessage: "",
        dismissChildSecs: 5,
        dismissChildCountDown: 0,
        dismissErrChildSecs: 5,
        dismissErrChildCountDown: 0,
        form: {
          email: "",
          family_name: "",
          given_name: "",
          nickname: "",
          user_id: "",
          user_metadata: {
            address: {
              street: "",
              postcode: "",
              city: "",
              country: ""
            },
            mobile: "",
            parentsof:[]
          }
        }
      }
    },
    created(){
      this.init();
    },
    methods: {
      async onSubmit(){
        if (this.form.user_id.startsWith('auth0|')){
          let userdata = {
            "user_id": this.form.user_id,
            "email": this.form.email,
            "given_name": this.form.given_name,
            "family_name": this.form.family_name,
            "nickname": this.form.nickname,
            "user_metadata": {
              "address": {
                "street": this.form.user_metadata.address.street,
                "postcode": this.form.user_metadata.address.postcode,
                "city": this.form.user_metadata.address.city,
                "country": this.form.user_metadata.address.country
              },
              "mobile": this.form.user_metadata.mobile,
            },
          }
          ProfileService.upsertUserDetails(userdata);
          this.showSuccessConfirm("Update own profile submitted successfully");
        } else {
          let userdata = {
            "user_id": this.form.user_id,
            "email": this.form.email,
            "user_metadata": {
              "address": {
                "street": this.form.user_metadata.address.street,
                "postcode": this.form.user_metadata.address.postcode,
                "city": this.form.user_metadata.address.city,
                "country": this.form.user_metadata.address.country
              },
              "mobile": this.form.user_metadata.mobile,
            },
          }
          ProfileService.upsertUserDetails(userdata);
          this.showSuccessConfirm("Update own profile submitted successfully");
        }
      },
      async onSubmitChildren(){
        if (this.form.user_id.startsWith('auth0|')){
          let userdata = {
            "user_id": this.form.user_id,
            "email": this.form.email,
            "given_name": this.form.given_name,
            "family_name": this.form.family_name,
            "nickname": this.form.nickname,
            "user_metadata": {
              "address": {
                "street": this.form.user_metadata.address.street,
                "postcode": this.form.user_metadata.address.postcode,
                "city": this.form.user_metadata.address.city,
                "country": this.form.user_metadata.address.country
              },
              "mobile": this.form.user_metadata.mobile,
              "parentsof": this.form.user_metadata.parentsof,
            },
          }
          ProfileService.upsertUserDetails(userdata);
        } else {
          let userdata = {
            "user_id": this.form.user_id,
            "email": this.form.email,
            "user_metadata": {
              "address": {
                "street": this.form.user_metadata.address.street,
                "postcode": this.form.user_metadata.address.postcode,
                "city": this.form.user_metadata.address.city,
                "country": this.form.user_metadata.address.country
              },
              "mobile": this.form.user_metadata.mobile,
              "parentsof": this.form.user_metadata.parentsof,
            },
          }
          ProfileService.upsertUserDetails(userdata);
        }

        // update child data
        this.form.user_metadata.parentsof.forEach(child => {
          if (child.user_id.startsWith('auth0|')){
            let userdata = {
              "user_id": child.user_id,
              "email": child.email,
              "given_name": child.given_name,
              "family_name": child.family_name,
              "nickname": child.nickname,
              "user_metadata": {
                "address": {
                  "street": this.form.user_metadata.address.street,
                  "postcode": this.form.user_metadata.address.postcode,
                  "city": this.form.user_metadata.address.city,
                  "country": this.form.user_metadata.address.country
                },
                "mobile": this.form.user_metadata.mobile,
              },
            }
            ProfileService.upsertUserDetails(userdata);
          }
        });
        this.showSuccessChildConfirm("Update children profile submitted successfully");
      },
      showSuccessConfirm(msg) {
        this.dismissCountDown = this.dismissSecs;
        this.alertMessage = msg;
      },
      showErrorConfirm(msg){
        this.dismissErrCountDown = this.dismissErrSecs
        this.alertErrMessage = msg;
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      countDownErrChanged(dismissErrCountDown) {
        this.dismissErrCountDown = dismissErrCountDown
      },
      showSuccessChildConfirm(msg) {
        this.dismissChildCountDown = this.dismissChildSecs;
        this.alertChildMessage = msg;
      },
      showErrorChildConfirm(msg){
        this.dismissErrChildCountDown = this.dismissErrChildSecs
        this.alertErrChildMessage = msg;
      },
      countDownChildChanged(dismissCountDown) {
        this.dismissChildCountDown = dismissCountDown
      },
      countDownErrChildChanged(dismissErrCountDown) {
        this.dismissErrChildCountDown = dismissErrCountDown
      },
      async init(){
        this.loading = true;
        await Promise.all ([this.populate(this.$auth.user.email)])
          .then(() => {
            this.loading = false;
          });
      },
      async populate(email){
        let userDetail = await ProfileService.getUserDetails(email);
        this.form.user_id = userDetail.user_id;
        this.form.given_name = typeof(userDetail.given_name)==="undefined" ? "" : userDetail.given_name;
        this.form.family_name = typeof(userDetail.family_name)==="undefined" ? "" : userDetail.family_name;
        this.form.nickname = typeof(userDetail.nickname)==="undefined" ? "" : userDetail.nickname;
        this.form.email = typeof(userDetail.email)==="undefined" ? "" : userDetail.email;

        if (typeof(userDetail.user_metadata)==="undefined"){
          this.form.user_metadata.mobile = "";
          this.form.user_metadata.address.street = "";
          this.form.user_metadata.address.postcode = "";
          this.form.user_metadata.address.city = "";
          this.form.user_metadata.address.country = "";
        } else {
          this.form.user_metadata.mobile = typeof(userDetail.user_metadata.mobile)==="undefined" ? "" : userDetail.user_metadata.mobile;
          this.form.user_metadata.address.street = typeof(userDetail.user_metadata.address.street)==="undefined" ? "" : userDetail.user_metadata.address.street;
          this.form.user_metadata.address.postcode = typeof(userDetail.user_metadata.address.postcode)==="undefined" ? "" : userDetail.user_metadata.address.postcode;
          this.form.user_metadata.address.city = typeof(userDetail.user_metadata.address.city)==="undefined" ? "" : userDetail.user_metadata.address.city;
          this.form.user_metadata.address.country = typeof(userDetail.user_metadata.address.country)==="undefined" ? "" : userDetail.user_metadata.address.country;

          this.form.user_metadata.parentsof = userDetail.user_metadata.parentsof;
        }
      },
    }
  } 
</script>

<style lang="sass" scoped>
</style>